/*------- 14. Brand logo style  -------*/

.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }

  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;

    img {
      filter: grayscale(100%);
      transition: all 0.5s ease 0s;
    }

    &:hover img {
      filter: grayscale(0%);
    }

    @media #{$xs-layout} {
      margin-top: 30px;
    }
  }
}

.brand-logo-wrap {
  padding: 20px 10px 2px 10px;

  @media #{$xs-layout} {
    padding: 10px 5px 2px 5px;
    ;
  }
}

.brand-slider-custom {
  width: 256px;
  height: 88px;

  @media #{$xs-layout} {
    width: 96px;
    height: 36px;
  }
}